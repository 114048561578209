import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import pathnames from "routes/pathnames";
// component
import AppFooter from "../components/app-footer";
import AppDrawer from "components/pages/menu/app-drawer";
import AppSwiperService from "components/pages/page-home/app-swiper-service";
import AppSwiper from "components/pages/page-home/app-swiper";

// assest
import logo from "assets/images/atf-logo.png";
import circleArrowIcon from "assets/images/circle-arrow-white.png";
import circleArrowBlueIcon from "assets/images/circle-arrow-blue.png";
import network from "assets/images/pages/page-service/network.png";
import devices from "assets/images/pages/page-service/devices.png";
import operation from "assets/images/pages/page-service/operation.png";
import resolutionImg from "assets/images/pages/page-home/thumb-it-resolution.jpg";
import squadImg from "assets/images/pages/page-home/thumb-delivery-squad.jpg";
import superappImg from "assets/images/pages/page-home/thumb-superapp.jpg";
import productImg from "assets/images/pages/page-home/thumb-ready-product.jpg";
import whatyouget01Icon from "assets/images/pages/page-service/digital-transformation_what-you-get/ico-whatyouget01.png";
import whatyouget02Icon from "assets/images/pages/page-service/digital-transformation_what-you-get/ico-whatyouget02.png";
import whatyouget03Icon from "assets/images/pages/page-service/digital-transformation_what-you-get/ico-whatyouget03.png";
import whatyouget04Icon from "assets/images/pages/page-service/digital-transformation_what-you-get/ico-whatyouget04.png";
import whatyouget05Icon from "assets/images/pages/page-service/digital-transformation_what-you-get/ico-whatyouget05.png";
import whatyouget06Icon from "assets/images/pages/page-service/digital-transformation_what-you-get/ico-whatyouget06.png";
import whatyouget07Icon from "assets/images/pages/page-service/digital-transformation_what-you-get/ico-whatyouget07.png";
import whatyouget08Icon from "assets/images/pages/page-service/digital-transformation_what-you-get/ico-whatyouget08.png";

const PageService = () => {
	const navigate = useNavigate();
	const [services, setServices] = useState({ overview: true, digital: false });

	const [overviewSelect, setOverviewSelect] = useState(false);

	const onSelect = (s: string) => {
		if (s === "overview") {
			setServices({ overview: true, digital: false });
			setOverviewSelect(true);
		} else {
			setServices({ overview: false, digital: true });
		}
	};

	const onHandleScrollAnimation = useCallback(() => {
		const w = document.getElementById("root");
		if (!w) return;
		const pageTop = w?.scrollTop;
		const ani1 = document.getElementById("animation-1");
		const ani2 = document.getElementById("animation-2");
		const ani3 = document.getElementById("animation-3");
		const ani4 = document.getElementById("animation-4");
		const ani5 = document.getElementById("animation-5");
		const ani6 = document.getElementById("animation-6");
		const ani7 = document.getElementById("animation-7");
		const ani8 = document.getElementById("animation-8");
		const ani9 = document.getElementById("animation-9");
		const ani10 = document.getElementById("animation-10");
		const pageBottom = pageTop + window.innerHeight;

		const onHandleAddClass = (item: any, i: number) => {
			setTimeout(() => {
				item.classList.add("fadeInAndUp");
			}, 60 * i);
		};

		if (ani1) {
			if (pageBottom + 100 > ani1.offsetTop + ani1.offsetTop / 3) {
				const list = ani1.querySelectorAll("div");
				for (let i = 0; i < list.length; ++i) {
					let item = list[i];
					onHandleAddClass(item, i);
				}
			}
		}
		if (ani2) {
			if (pageBottom + 500 > ani2.offsetTop + ani2.offsetTop / 3) {
				const list = ani2.querySelectorAll("div");
				for (let i = 0; i < list.length; ++i) {
					let item = list[i];
					onHandleAddClass(item, i);
				}
			}
		}
		if (ani3) {
			if (pageTop + 700 > ani3.offsetHeight) {
				ani3?.getElementsByClassName("button-container")[0]?.classList?.add("fadeInAndUp");
			}
		}
		if (ani4) {
			if (pageBottom + 1200 > ani4.offsetTop + ani4.offsetTop / 3) {
				const list = ani4.querySelectorAll("div");
				for (let i = 0; i < list.length; ++i) {
					let item = list[i];
					onHandleAddClass(item, i);
				}
			}
		}
		if (ani5) {
			if (pageTop > ani5.offsetHeight) {
				ani5?.getElementsByClassName("left")[0]?.classList?.add("fadeInAndRight");
			}
		}
		if (ani6) {
			if (pageTop + 900 > ani6.offsetHeight) {
				ani6?.getElementsByClassName("left")[0]?.classList?.add("fadeInAndRight");
			}
		}
		if (ani7) {
			if (pageTop > ani7.offsetHeight) {
				ani7?.getElementsByClassName("top-title")[0]?.classList?.add("fadeInAndUp");
			}
		}
		if (ani8) {
			if (pageTop > ani8.offsetHeight) {
				ani8?.getElementsByClassName("pics")[0]?.classList?.add("fadeInAndRight");
				ani8?.getElementsByClassName("text")[0]?.classList?.add("fadeInAndLeft");
			}
		}
		if (ani9) {
			if (pageTop - 400 > ani9.offsetHeight) {
				ani9?.getElementsByClassName("pics")[0]?.classList?.add("fadeInAndRight");
				ani9?.getElementsByClassName("text")[0]?.classList?.add("fadeInAndLeft");
			}
		}
		if (ani10) {
			if (pageTop - 600 > ani10.offsetHeight) {
				ani10?.getElementsByClassName("pics")[0]?.classList?.add("fadeInAndRight");
				ani10?.getElementsByClassName("text")[0]?.classList?.add("fadeInAndLeft");
			}
		}
	}, []);

	useEffect(() => {
		const w = document.getElementById("root");
		if (w) {
			w.addEventListener("scroll", onHandleScrollAnimation, { capture: true });
			return () => w.removeEventListener("scroll", onHandleScrollAnimation);
		}
	}, [onHandleScrollAnimation]);

	const onHandleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const value = event.currentTarget.value;
		if (value === "overview") {
			setServices({ overview: true, digital: false });
		} else {
			setServices({ overview: false, digital: true });
		}
	};

	return (
		<div className="page-service">
			<div className="service">
				<div className="section-1">
					<div className="top-menu">
						<div className="img-size">
							<Link to={pathnames.PageHome}>
								<img src={logo} alt="logo" />
							</Link>
							<AppDrawer />
						</div>
					</div>
					<Link to={pathnames.PageChatWithUs} className="chat-container">
						<button className="chat-container__btn">
							<div className="icon"></div>
							<div className="showT">Chat with us</div>
						</button>
					</Link>
					<div className="section-1__main-img">
						<div className="center">
							<div className="content-container">
								<div className="content__title">
									<span>It's time to change the</span> <br /> <span className="content__title--highlight">Business Culture</span>
								</div>
								<div className="content__description">
									<p>For those who weren’t convinced about the positive benefits of digital transformation, the power of digitization won over many doubters during the COVID-19 pandemic.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={services.overview ? "section-2" : "section-2--white"}>
					<div className="overview">
						<div className="content">
							<span className={services.overview ? "content__description active" : "content__description"} onClick={() => onSelect("overview")}>
								<span className="hoverT">/</span> Overview
							</span>
							<span className={services.digital ? "content__description active" : "content__description"} onClick={() => onSelect("digital")}>
								<span className="hoverT">/</span> Digital Transformation
							</span>
						</div>
						<div className="dropdown">
							<div className="dropdown__wrapper">
								<span className="slash">/</span>
								<select className="select-frame" onChange={onHandleSelect}>
									<option value="overview" selected={services.overview}>
										Overview
									</option>
									<option value="digital" selected={services.digital}>
										Digital Transformation
									</option>
								</select>
							</div>
						</div>
					</div>
					<div className="section-2__container">
						{services.overview && (
							<>
								<div className="overview-tab">
									<div className={overviewSelect === false ? "overview-tab__title" : "overview-tab__title fadeInAndRight"} id={overviewSelect === false ? "animation-6" : ""}>
										<div className="left">
											<p>/</p> What We Can Do
										</div>
									</div>
									<div className={overviewSelect === false ? "overview-tab__list" : "overview-tab__list fadeInAndUp"} id={overviewSelect === false ? "animation-1" : ""}>
										<div className={overviewSelect === false ? "wrapper show-animation" : "wrapper"}>
											<div className="center">
												<span>IT Solution</span>
												<ul>
													<li>Technical resolution and architecting</li>
													<li>Business digitalization and transformation</li>
												</ul>
												<p>01</p>
											</div>
										</div>
										<div className={overviewSelect === false ? "wrapper show-animation" : "wrapper"}>
											<div className="center">
												<span>UXUI Design</span>
												<ul>
													<li>Wireframe, user flow, visual design, style guide for web portals and mobile apps</li>
												</ul>
												<p>02</p>
											</div>
										</div>
										<div className={overviewSelect === false ? "wrapper show-animation" : "wrapper"}>
											<div className="center">
												<span>Development</span>
												<ul>
													<li>Frontend (flutter, react, angular)</li>
													<li>Backend (java, dotnet)</li>
													<li>Regulatory compliance</li>
													<li>DevOps, data engineering, RPA, test automation</li>
												</ul>
												<p>03</p>
											</div>
										</div>
										<div className={overviewSelect === false ? "wrapper show-animation" : "wrapper"}>
											<div className="center">
												<span>Consultation</span>
												<ul>
													<li>IT Consultation</li>
													<li>Collaboration on R7D initiatives</li>
												</ul>
												<p>04</p>
											</div>
										</div>
										<div className={overviewSelect === false ? "wrapper show-animation" : "wrapper"}>
											<div className="center">
												<span>Delivery Squad</span>
												<ul>
													<li>Team setup for agile project deliveries</li>
													<li>Full squad with scrum master, BA, developers and QA testers</li>
												</ul>
												<p>05</p>
											</div>
										</div>
										<div className={overviewSelect === false ? "wrapper show-animation" : "wrapper"}>
											<div className="center">
												<span>Support & Operation</span>
												<ul>
													<li>Applications support and maintenance</li>
													<li>Penetration & load test</li>
													<li>Cloud storage & hosting </li>
													<li>Cloud migration</li>
												</ul>
												<p>06</p>
											</div>
										</div>
										<div className={overviewSelect === false ? "wrapper show-animation" : "wrapper"}>
											<div className="center">
												<span>Hardware</span>
												<ul>
													<li>IT Devices and Workstations</li>
													<li>IoT Devices and Sensors</li>
												</ul>
												<p>07</p>
											</div>
										</div>
										<div className={overviewSelect === false ? "wrapper show-animation" : "wrapper"}>
											<div className="center">
												<span>Systems Integration</span>
												<ul>
													<li>Various integration with partners platform (ie. WhatsApp, SMS, IVR, AI, Data Analytics, Chatbot, Zoom)</li>
												</ul>
												<p>08</p>
											</div>
										</div>
										<div className={overviewSelect === false ? "wrapper show-animation" : "wrapper"}>
											<div className="center">
												<span>Product Distributor</span>
												<ul>
													<li>Reseller and solutions provider on ready products from principal</li>
													<li>ERP, smart technologies to city, forklift, hotel, medical, office, warehouse, factory, utilities and supply chain</li>
												</ul>
												<p>09</p>
											</div>
										</div>
										{window.screen.width < 1212 && <div className={overviewSelect === false ? "wrapper wrapper--transparent show-animation" : "wrapper wrapper--transparent"}></div>}
									</div>
								</div>
								<div className="overview-swiper">
									<div className="title">
										<span>Why Altecflex</span>
									</div>
									<div className="middle">
										<div className="divider"></div>
									</div>
									<div className="swiper-container">
										<AppSwiper props="overview" />
									</div>
								</div>
							</>
						)}
						{services.digital && (
							<div className="digital-tab">
								<div>
									{" "}
									{/* id="animation-7" */}
									<p className="top-title fadeInAndUp">WHAT Is Digital Transformation</p>
									<div className="middle fadeInAndUp">
										<div className="divider"></div>
									</div>
								</div>
								<div className="center">
									{/* id="animation-8" */}
									<div className="pics fadeInAndRight">
										<img src={network} alt="network" />
									</div>
									<div className="text fadeInAndLeft">
										Digital transformation involves using digital technologies to remake a process to become more efficient or effective. The idea is to use technology not just to replicate an existing service
										in a digital form, but to use technology to transform that service into something significantly better.
									</div>
								</div>
								{window.screen.width < 700 && <br />}
								<div className="center--text">However, it’s not just about the technology, changing business processes and corporate culture are just as vital to the success of these initiatives.</div>
								<div className="center" id="animation-9">
									{window.screen.width < 700 && (
										<div className="pics">
											<img src={devices} alt="devices" />
										</div>
									)}
									<div className="text">
										While digital transformation is one of the most commonly used phrases in the IT industry, definitions vary. What everyone can agree on it that, beneath the hype, the fluff and the confusion,
										digital transformation involves some pretty important changes to business culture.
									</div>
									{window.screen.width > 701 && (
										<div className="pics">
											<img src={devices} alt="devices" />
										</div>
									)}
								</div>
								<div className="center" id="animation-10">
									<div className="pics">
										<img src={operation} alt="operation" />
									</div>
									<div className="text">
										<div>
											Digital transformation is changing the way an organization operates. Systems, processes, workflow, and culture are all evaluated. This transformation affects each level of an
											organization and brings together data across areas to work together more effectively.
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>

				{services.overview && (
					<div className="section-3">
						<div className="pt">
							<div className="section-3__wrapper">
								<div className="row" id="animation-2">
									<div className="column card">
										<img src={resolutionImg} alt="resolutionImg" />
										<p>IT Resolution & Strategy</p>
										<span>Consultation with assessment of fundamentally rethinking to the business models and processes.</span>
									</div>
									<div className="column card">
										<img src={squadImg} alt="squadImg" />
										<p>Digitalization Delivery Squad</p>
										<span>Digital team setup to deliver your MVPs.</span>
									</div>
									<div className="column card">
										<img src={superappImg} alt="superappImg" />
										<p>Super App Development</p>
										<span>A closed ecosystem of many apps that people would use everyday.</span>
									</div>
									<div className="column card">
										<img src={productImg} alt="productImg" />
										<p>The Ready Products</p>
										<span>We provide recommendation and customization to what works best to your use cases.</span>
										<div>
											<button
												className="btn"
												onClick={() => {
													navigate(pathnames.PageProduct);
												}}
											>
												<div>FIND OUT MORE</div>
												<div className="arrowW">
													<img src={circleArrowIcon} alt="white arrow" />
												</div>
												<div className="arrowB">
													<img src={circleArrowBlueIcon} alt="blue arrow" />
												</div>
											</button>
										</div>
									</div>
								</div>
							</div>
							<div className="section-3__slider">
								<div className="swiper-container">
									<AppSwiperService props={"service"} />
								</div>
							</div>
							<div className="section-3__slider"></div>
							{/* <div className="section-3__wrapper" id="animation-3">
								<div className="button-container">
									<button
										className="btn"
										onClick={() => {
											navigate(pathnames.PageProduct);
										}}
									>
										<div>FIND OUT MORE</div>
										<div className="arrowW">
											<img src={circleArrowIcon} alt="white arrow" />
										</div>
										<div className="arrowB">
											<img src={circleArrowBlueIcon} alt="blue arrow" />
										</div>
									</button>
								</div>
							</div> */}
						</div>
					</div>
				)}

				{services.digital && (
					<div className="section-4" id="animation-5">
						<div className="left">
							<span className="left__highlight">/</span> <br />
							<div className="left__title">
								<span>WHAT You Get</span>
							</div>
							<div className="middle">
								<div className="divider"></div>
							</div>
							<span className="left__info">
								By taking advantage of workflow automation and advanced processing, such as artificial intelligence (AI) and machine learning (ML), companies can connect the dots on the customer journey in a way
								that wasn’t possible before.
							</span>
						</div>
						<div className="right" id="animation-4">
							<div className="box">
								<span>Enhanced data collection</span>
								<img src={whatyouget01Icon} alt="whatyouget01Icon" />
							</div>
							<div className="box">
								<span>Greater resource management</span>
								<img src={whatyouget02Icon} alt="whatyouget02Icon" />
							</div>
							<div className="box">
								<span>Data-driven customer insights</span>
								<img src={whatyouget03Icon} alt="whatyouget03Icon" />
							</div>
							<div className="box">
								<span>An overall better customer experience</span>
								<img src={whatyouget04Icon} alt="whatyouget04Icon" />
							</div>
							<div className="box">
								<span>Encourages digital culture (with improved collaboration)</span>
								<img src={whatyouget05Icon} alt="whatyouget05Icon" />
							</div>
							<div className="box">
								<span>Encourages digital culture (with improved collaboration)</span>
								<img src={whatyouget06Icon} alt="whatyouget06Icon" />
							</div>
							<div className="box">
								<span>Increased agility</span>
								<img src={whatyouget07Icon} alt="whatyouget07Icon" />
							</div>
							<div className="box">
								<span>Improved productivity</span>
								<img src={whatyouget08Icon} alt="whatyouget08Icon" />
							</div>
							{(window.screen.width > 1212 || window.screen.width < 991) && <div className="box box--transparent"></div>}
						</div>
						<div className="get-swipper">
							<AppSwiper props="get" />
						</div>
					</div>
				)}

				<AppFooter />
			</div>
		</div>
	);
};

export default PageService;
