import { useMemo, useState } from "react";
import { RouterProvider } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "themes/theme";
import { router } from "routes/routes";
import classNames from "common/class-names";
import { ReactComponent as ProductFramesSvg } from "assets/images/product-frames.svg";
import { ReactComponent as ButtonFramesSvg } from "assets/images/button-frames.svg";

const App = () => {
	const [botVisible, setBotVisible] = useState(false);

	//prettier-ignore
	const botClassname = useMemo(() => (classNames({
		"atf-chatbot": true,
		"atf-chatbot--active": botVisible
	})), [botVisible]);

	//prettier-ignore
	const buttonBotClassname = useMemo(() => (classNames({
		"atf-chatbot-button": true,
		"atf-chatbot-button--active": botVisible
	})), [botVisible]);

	const onHandleDisplayBot = () => {
		setBotVisible((prev) => !prev);
	};

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<ProductFramesSvg />
			<ButtonFramesSvg />
			<RouterProvider router={router} />

			<button type="button" className={buttonBotClassname} onClick={onHandleDisplayBot} />

			<div className={botClassname}>
				<button type="button" className="atf-chatbot__close" onClick={onHandleDisplayBot} />
				<iframe className="atf-chatbot__iframe" id="atf-chat-bot-iframe" src="https://embed.chatnode.ai/c434785d522aae5e" title="ATF chatbot" />
			</div>
		</ThemeProvider>
	);
};

export default App;
