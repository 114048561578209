import { createBrowserRouter, RouteObject } from "react-router-dom";
import pathnames from "routes/pathnames";
import PageHome from "pages/page-home";
import PageWhoWeAre from "pages/page-who-we-are";
import PageService from "pages/page-service";
import PageChatWithUs from "pages/page-chat-with-us";
import PageProduct from "pages/page-product";
import ScrollToTop from "common/scroll-to-top";

const routes = [
	{
		path: pathnames.PageHome,
		element: PageHome,
	},
	{
		path: pathnames.PageWhoWeAre,
		element: PageWhoWeAre,
	},
	{
		path: pathnames.PageWhoWeAreJoin,
		element: PageWhoWeAre,
	},
	{
		path: pathnames.PageService,
		element: PageService,
	},
	{
		path: pathnames.PageChatWithUs,
		element: PageChatWithUs,
	},
	{
		path: pathnames.PageProduct,
		element: PageProduct,
	},
];

export default routes;

const enrichedRouteObject: RouteObject[] = routes.map(({ element, ...res }) => {
	const Page = element;
	const routeObject: RouteObject = res;
	routeObject.element = (
		<ScrollToTop>
			<Page />
		</ScrollToTop>
	);
	return routeObject;
});

export const router = createBrowserRouter(enrichedRouteObject);
