import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import aceattImg from "../../../assets/images/pages/page-home/strategic-partners/aceatt.png";
import awsImg from "../../../assets/images/pages/page-home/strategic-partners/aws.png";
import nextEraImg from "../../../assets/images/pages/page-home/strategic-partners/next-era.png";
import remicleImg from "../../../assets/images/pages/page-home/strategic-partners/remicle.png";
import teamCloudImg from "../../../assets/images/pages/page-home/strategic-partners/team-cloud.png";
import vonageImg from "../../../assets/images/pages/page-home/strategic-partners/vonage.png";
import zoomImg from "../../../assets/images/pages/page-home/strategic-partners/zoom.png";
import huaweiImg from "../../../assets/images/pages/page-home/strategic-partners/huawei.png";
import adImg from "../../../assets/images/pages/page-home/strategic-partners/mega-advertising.png";
import verintImg from "../../../assets/images/pages/page-home/strategic-partners/verint.png";
import cv01Icon from "assets/images/pages/page-who-we-are/who-we-are_our-core-value/ico-corevalue01.png";
import cv02Icon from "assets/images/pages/page-who-we-are/who-we-are_our-core-value/ico-corevalue02.png";
import cv03Icon from "assets/images/pages/page-who-we-are/who-we-are_our-core-value/ico-corevalue03.png";
import cv04Icon from "assets/images/pages/page-who-we-are/who-we-are_our-core-value/ico-corevalue04.png";
import cv05Icon from "assets/images/pages/page-who-we-are/who-we-are_our-core-value/ico-corevalue05.png";
import whyatf01Icon from "assets/images/pages/page-who-we-are/join-the-team/ico-whyatf01.png";
import whyatf02Icon from "assets/images/pages/page-who-we-are/join-the-team/ico-whyatf02.png";
import whyatf03Icon from "assets/images/pages/page-who-we-are/join-the-team/ico-whyatf03.png";
import whyatf04Icon from "assets/images/pages/page-who-we-are/join-the-team/ico-whyatf04.png";
import whyatf05Icon from "assets/images/pages/page-who-we-are/join-the-team/ico-whyatf05.png";
import whyatf06Icon from "assets/images/pages/page-who-we-are/join-the-team/ico-whyatf06.png";
import whyatf07Icon from "assets/images/pages/page-who-we-are/join-the-team/ico-whyatf07.png";
import whyatf08Icon from "assets/images/pages/page-who-we-are/join-the-team/ico-whyatf08.png";
import whatyouget01Icon from "assets/images/pages/page-service/digital-transformation_what-you-get/ico-whatyouget01.png";
import whatyouget02Icon from "assets/images/pages/page-service/digital-transformation_what-you-get/ico-whatyouget02.png";
import whatyouget03Icon from "assets/images/pages/page-service/digital-transformation_what-you-get/ico-whatyouget03.png";
import whatyouget04Icon from "assets/images/pages/page-service/digital-transformation_what-you-get/ico-whatyouget04.png";
import whatyouget05Icon from "assets/images/pages/page-service/digital-transformation_what-you-get/ico-whatyouget05.png";
import whatyouget06Icon from "assets/images/pages/page-service/digital-transformation_what-you-get/ico-whatyouget06.png";
import whatyouget07Icon from "assets/images/pages/page-service/digital-transformation_what-you-get/ico-whatyouget07.png";
import whatyouget08Icon from "assets/images/pages/page-service/digital-transformation_what-you-get/ico-whatyouget08.png";
import atoIcon from "assets/images/pages/page-product/products_ecomm/ico-ato.png";
import integrationIcon from "assets/images/pages/page-product/products_ecomm/ico-integration.png";
import technologyIcon from "assets/images/pages/page-product/products_ecomm/ico-technology.png";
import affordabilityIcon from "assets/images/pages/page-product/products_ecomm/ico-affordability.png";
import streamingIcon from "assets/images/pages/page-product/products_ecomm/key-features/ico-Live Video Streaming.png";
import chatIcon from "assets/images/pages/page-product/products_ecomm/key-features/ico-Online Chat.png";
import catalogueIcon from "assets/images/pages/page-product/products_ecomm/key-features/ico-E-Catalogue.png";
import gatewayIcon from "assets/images/pages/page-product/products_ecomm/key-features/ico-Payment Gateway Integration.png";
import videoIcon from "assets/images/pages/page-product/products_ecomm/key-features/ico-Short Videos.png";
import managementIcon from "assets/images/pages/page-product/products_ecomm/key-features/ico-Event Management.png";
import programIcon from "assets/images/pages/page-product/products_ecomm/key-features/ico-Loyalty Program.png";
import integration1Icon from "assets/images/pages/page-product/products_lms/ico-integration.png";
import assignmentIcon from "assets/images/pages/page-product/products_lms/ico-Task assignment.png";
import engineIcon from "assets/images/pages/page-product/products_lms/ico-Scorecard engine.png";
import underWritingIcon from "assets/images/pages/page-product/products_lms/ico-Underwriting.png";
import routingIcon from "assets/images/pages/page-product/products_lms/ico-Application routing.png";
import configurationIcon from "assets/images/pages/page-product/products_lms/ico-Workflow Configuration.png";
import alicloudImg from "assets/images/pages/page-home/strategic-partners/partner-alicloud.png";
import nexagateImg from "assets/images/pages/page-home/strategic-partners/partner-nexagate.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";

// import required modules
import { Scrollbar, Autoplay } from "swiper";

import { styled } from "@mui/system";

const AppSwiper = ({ props }: any) => {
	const StyledSwiper = styled(Swiper)(({ theme }: any) => ({
		padding: "0",
	}));

	return (
		<>
			<StyledSwiper
				slidesPerView={1}
				spaceBetween={10}
				autoplay={{
					delay: 2500,
					disableOnInteraction: false,
				}}
				breakpoints={{
					640: {
						slidesPerView: 2,
						spaceBetween: 20,
					},
					768: {
						slidesPerView: 4,
						spaceBetween: 40,
					},
					1024: {
						slidesPerView: 5,
						spaceBetween: 50,
					},
					1152: {
						slidesPerView: 6,
						spaceBetween: 60,
					},
					1280: {
						slidesPerView: 7,
						spaceBetween: 70,
					},
					1408: {
						slidesPerView: 8,
						spaceBetween: 80,
					},
					1536: {
						slidesPerView: 9,
						spaceBetween: 90,
					},
					1664: {
						slidesPerView: 10,
						spaceBetween: 100,
					},
					1792: {
						slidesPerView: 11,
						spaceBetween: 110,
					},
					1920: {
						slidesPerView: 12,
						spaceBetween: 120,
					},
				}}
				scrollbar={{
					hide: false,
				}}
				modules={[Scrollbar, Autoplay]}
				className="mySwiper"
				sx={{
					"& .swiper-scrollbar swiper-scrollbar-horizontal": { background: "#BED9FA" },
					"& .swiper-scrollbar-horizontal": { bottom: "0px" },
					"& .swiper-scrollbar-drag": { background: "#116AD3" },
				}}
			>
				{props === "strategic" && (
					<>
						<SwiperSlide>
							<div className="box-center">
								<div className="box">
									<img src={aceattImg} alt="aceattImg" />
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="box-center">
								<div className="box">
									<img src={alicloudImg} alt="alicloudImg" />
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="box-center">
								<div className="box">
									<img src={awsImg} alt="awsImg" />
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="box-center">
								<div className="box">
									<img src={huaweiImg} alt="huaweiImg" />
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="box-center">
								<div className="box">
									<img src={adImg} alt="adImg" />
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="box-center">
								<div className="box">
									<img src={nextEraImg} alt="nextEraImg" />
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="box-center">
								<div className="box">
									<img src={remicleImg} alt="remicleImg" />
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="box-center">
								<div className="box">
									<img src={teamCloudImg} alt="teamCloudImg" />
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="box-center">
								<div className="box">
									<img src={verintImg} alt="verintImg" />
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="box-center">
								<div className="box">
									<img src={vonageImg} alt="vonageImg" />
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="box-center">
								<div className="box">
									<img src={nexagateImg} alt="nexagateImg" />
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="box-center">
								<div className="box">
									<img src={zoomImg} alt="zoomImg" />
								</div>
							</div>
						</SwiperSlide>
					</>
				)}

				{props === "core" && (
					<>
						<SwiperSlide>
							<div className="core">
								<div style={{ height: "150px" }}>
									<span className="core--text">Skills and knowledge with modern technology stacks</span>
								</div>
								<div className="core--icon">
									<img src={cv01Icon} alt="cv01Icon" style={{ width: "64px", height: "64px" }} />
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="core">
								<div style={{ height: "150px" }}>
									<span className="core--text">Shared resources pool of 160+ consultants together with partner companies</span>
								</div>
								<div className="core--icon">
									<img src={cv02Icon} alt="cv02Icon" style={{ width: "64px", height: "64px" }} />
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="core">
								<div style={{ height: "150px" }}>
									<span className="core--text">Our consultants are trained with working exposure to worldwide projects implementation</span>
								</div>
								<div className="core--icon">
									<img src={cv03Icon} alt="cv03Icon" style={{ width: "64px", height: "64px" }} />
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="core">
								<div style={{ height: "150px" }}>
									<span className="core--text">Culture with knowledge sharing and thinking process focus</span>
								</div>
								<div className="core--icon">
									<img src={cv04Icon} alt="cv04Icon" style={{ width: "64px", height: "64px" }} />
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="core">
								<div style={{ height: "150px" }}>
									<span className="core--text">Regional support and deliveries capability based at Malaysia for international clients.</span>
								</div>
								<div className="core--icon">
									<img src={cv05Icon} alt="cv05Icon" style={{ width: "64px", height: "64px" }} />
								</div>
							</div>
						</SwiperSlide>
					</>
				)}

				{props === "core1" && (
					<>
						<SwiperSlide>
							<div className="features">
								<div className="box box--short">
									<div style={{ height: "147px", marginTop: "20px" }}>
										<div className="box__info">Skills and knowledge with modern technology stacks</div>
									</div>
									<div className="box__icon">
										<img src={cv01Icon} alt="cv01Icon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="features">
								<div className="box box--short">
									<div style={{ height: "147px", marginTop: "20px" }}>
										<div className="box__info">Shared resources pool of 160+ consultants together with partner companies</div>
									</div>
									<div className="box__icon">
										<img src={cv02Icon} alt="cv02Icon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="features">
								<div className="box box--short">
									<div style={{ height: "147px", marginTop: "20px" }}>
										<div className="box__info">Our consultants are trained with working exposure to worldwide projects implementation</div>
									</div>
									<div className="box__icon">
										<img src={cv03Icon} alt="cv03Icon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="features">
								<div className="box box--short">
									<div style={{ height: "147px", marginTop: "20px" }}>
										<div className="box__info">Culture with knowledge sharing and thinking process focus</div>
									</div>
									<div className="box__icon">
										<img src={cv04Icon} alt="cv04Icon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="features">
								<div className="box box--short">
									<div style={{ height: "147px", marginTop: "20px" }}>
										<div className="box__info">Regional support and deliveries capability based at Malaysia for international clients.</div>
									</div>
									<div className="box__icon">
										<img src={cv05Icon} alt="cv05Icon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
					</>
				)}

				{props === "join" && (
					<>
						<SwiperSlide>
							<div className="join">
								<div className="box">
									<div style={{ height: "250px" }}>
										<div className="box__title">Exposure to International Digital Transformation Initiatives</div>
										<div className="box__info">
											ATF serves multinational clients across region such as (Singapore, Philippines, Thailand, German) providing vast exposure towards different culture & collaboration experiences.
										</div>
									</div>
									<div className="box__icon">
										<img src={whyatf01Icon} alt="whyatf01Icon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="join">
								<div className="box">
									<div style={{ height: "250px" }}>
										<div className="box__title">Individual Career Development</div>
										<div className="box__info">ATF provides tailor made career development to grow each individual towards their desired career path.</div>
									</div>
									<div className="box__icon">
										<img src={whyatf02Icon} alt="whyatf02Icon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="join">
								<div className="box">
									<div style={{ height: "250px" }}>
										<div className="box__title">Enterprise Standard and Mindset </div>
										<div className="box__info">
											ATF grooms employees by making sure stakeholder possess the right mindset to match up with Enterprise Standard. “No Blame Culture” is one of the proven successful practice that we had
											followed all this time.
										</div>
									</div>
									<div className="box__icon">
										<img src={whyatf03Icon} alt="whyatf03Icon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="join">
								<div className="box">
									<div style={{ height: "250px" }}>
										<div className="box__title">Worldwide Projects Across Industries</div>
										<div className="box__info">With ATF, you are given a chance to contribute and expand your knowledge throughout different digital industries (e.g. Fintech, Insurtech, ERP & etc)</div>
									</div>
									<div className="box__icon">
										<img src={whyatf04Icon} alt="whyatf04Icon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="join">
								<div className="box">
									<div style={{ height: "250px" }}>
										<div className="box__title">Opportunity to Relocate Other Country</div>
										<div className="box__info">
											Provided we have few branches across SEA region, candidates that is adventurous will be offered a chance to be relocated to overseas to support client / maintain relationships.
										</div>
									</div>
									<div className="box__icon">
										<img src={whyatf05Icon} alt="whyatf05Icon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="join">
								<div className="box">
									<div style={{ height: "250px" }}>
										<div className="box__title">Knowledge Sharing Culture</div>
										<div className="box__info">ATF will make sure new joiners is briefed and prepared before deploying into projects, knowledge sharing is a must-do.</div>
									</div>
									<div className="box__icon">
										<img src={whyatf06Icon} alt="whyatf01Icon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="join">
								<div className="box">
									<div style={{ height: "250px" }}>
										<div className="box__title">Teamwork Delivery and Objective Driven</div>
										<div className="box__info">We work as a team player to ensure the delivery happens within timeline with minimal gap. In short, we got each others back.</div>
									</div>
									<div className="box__icon">
										<img src={whyatf07Icon} alt="whyatf07Icon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="join">
								<div className="box">
									<div style={{ height: "250px" }}>
										<div className="box__title">Employee Benefits</div>
										<div className="box__info">We study and revise employee benefits time to time to match market standards, making sure our employees enjoy maximum benefits.</div>
									</div>
									<div className="box__icon">
										<img src={whyatf08Icon} alt="whyatf08Icon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
					</>
				)}

				{props === "live" && (
					<>
						<SwiperSlide>
							<div className="join">
								<div className="box box--aqua">
									<div className="box__title">ASSEMBLE TO ORDER (ATO)</div>
									<div className="box__info">Ready component to be customised based on business needs, short implementation time.</div>
									<div className="box__icon">
										<img src={atoIcon} alt="atoIcon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="join">
								<div className="box box--aqua">
									<div className="box__title">INTEGRATION</div>
									<div className="box__info">Integrate with any systems for information exchange.</div>
									<div className="box__icon">
										<img src={integrationIcon} alt="integrationIcon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="join">
								<div className="box box--aqua">
									<div className="box__title">TECHNOLOGY</div>
									<div className="box__info">Keeping up with the latest implementation technology time to time.</div>
									<div className="box__icon">
										<img src={technologyIcon} alt="technologyIcon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="join">
								<div className="box box--aqua">
									<div className="box__title">AFFORDABILITY</div>
									<div className="box__info">Lower price point compared with average market price.</div>
									<div className="box__icon">
										<img src={affordabilityIcon} alt="affordabilityIcon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
					</>
				)}

				{props === "lead" && (
					<>
						<SwiperSlide>
							<div className="join">
								<div className="box box--aqua">
									<div className="box__title">INTEGRATION</div>
									<div className="box__info">Open API to integrate with internal system to collect lead records in batch for processing and managing in LMS system.</div>
									<div className="box__icon">
										<img src={integration1Icon} alt="integration1Icon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="join">
								<div className="box box--aqua">
									<div className="box__title">TASK ASSIGNMENT</div>
									<div className="box__info">Automatically distribute leads to sales user to secure a deal.</div>
									<div className="box__icon">
										<img src={assignmentIcon} alt="assignmentIcon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="join">
								<div className="box box--aqua">
									<div className="box__title">SCORECARD ENGINE</div>
									<div className="box__info">Manage and configure scorecard to fit marketing evaluation needs, provide automated lead scoring.</div>
									<div className="box__icon">
										<img src={engineIcon} alt="engineIcon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="join">
								<div className="box box--aqua">
									<div className="box__title">UNDERWRITING</div>
									<div className="box__info">Capability of user to overwrite scorecard output after detailed study is conducted towards lead records.</div>
									<div className="box__icon">
										<img src={underWritingIcon} alt="underWritingIcon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="join">
								<div className="box box--aqua">
									<div className="box__title">APPLICATION ROUTING</div>
									<div className="box__info">Sales user to reroute lead back to marketing for further nurturing / evaluation for unsuccessful conversion lead records.</div>
									<div className="box__icon">
										<img src={routingIcon} alt="routingIcon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="join">
								<div className="box box--aqua">
									<div className="box__title">WORKFLOW CONFIGURATION</div>
									<div className="box__info">Flexibility to configure workflow to keep up with market trends. Provided with the capability to spot and improvise workflow on the fly.</div>
									<div className="box__icon">
										<img src={configurationIcon} alt="configurationIcon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
					</>
				)}

				{props === "features" && (
					<>
						<SwiperSlide>
							<div className="features">
								<div className="box box--short">
									<div style={{ height: "167px" }}>
										<div className="box__title">LIVE VIDEO STREAMING</div>
										<div className="box__info">Feature to connect buyer and seller through video streaming. Buyer can still revisit live streaming session that they missed.</div>
									</div>
									<div className="box__icon">
										<img src={streamingIcon} alt="streamingIcon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="features">
								<div className="box box--short">
									<div style={{ height: "167px" }}>
										<div className="box__title">ONLINE CHAT</div>
										<div className="box__info">We also offers communication tool to connect buyer & seller in case of any enquiries.</div>
									</div>
									<div className="box__icon">
										<img src={chatIcon} alt="chatIcon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="features">
								<div className="box box--short">
									<div style={{ height: "167px" }}>
										<div className="box__title">E-CATALOGUE</div>
										<div className="box__info">Buyer to browse product listed by seller.</div>
									</div>
									<div className="box__icon">
										<img src={catalogueIcon} alt="catalogueIcon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="features">
								<div className="box box--short">
									<div style={{ height: "167px" }}>
										<div className="box__title">PAYMENT GATEWAY INTEGRATION</div>
										<div className="box__info">Payment made easier via online payment gateway (FPX, Credit/Debit Card, TnG-Ewallet).</div>
									</div>
									<div className="box__icon">
										<img src={gatewayIcon} alt="gatewayIcon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="features">
								<div className="box box--short">
									<div style={{ height: "167px" }}>
										<div className="box__title">SHORT VIDEOS / STORY</div>
										<div className="box__info">Seller's capability to create short sales pitch video content to attract buyers.</div>
									</div>
									<div className="box__icon">
										<img src={videoIcon} alt="videoIcon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="features">
								<div className="box box--short">
									<div style={{ height: "167px" }}>
										<div className="box__title">EVENT MANAGEMENT</div>
										<div className="box__info">Keep customer posted on latest announcement / event by providing flexibility for user to configure banner content in real time.</div>
									</div>
									<div className="box__icon">
										<img src={managementIcon} alt="managementIcon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="features">
								<div className="box box--short">
									<div style={{ height: "167px" }}>
										<div className="box__title">LOYALTY PROGRAM</div>
										<div className="box__info">Retain customer by tracking customer spending points to be offered as rewards / discounts on their next purchases.</div>
									</div>
									<div className="box__icon">
										<img src={programIcon} alt="programIcon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
					</>
				)}

				{props === "overview" && (
					<>
						<SwiperSlide>
							<div className="overview1">
								<div className="wrapper">
									<div className="center">
										<span>IT Solution</span>
										<ul>
											<li>Technical resolution and architecting</li>
											<li>Business digitalization and transformation</li>
										</ul>
										<p>01</p>
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="overview1">
								<div className="wrapper">
									<div className="center">
										<span>UXUI Design</span>
										<ul>
											<li>Wireframe, user flow, visual design, style guide for web portals and mobile apps</li>
										</ul>
										<p>02</p>
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="overview1">
								<div className="wrapper">
									<div className="center">
										<span>Development</span>
										<ul>
											<li>Frontend (flutter, react, angular)</li>
											<li>Backend (java, dotnet)</li>
											<li>Regulatory compliance</li>
											<li>DevOps, data engineering, RPA, test automation</li>
										</ul>
										<p>03</p>
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="overview1">
								<div className="wrapper">
									<div className="center">
										<span>Consultation</span>
										<ul>
											<li>IT Consultation</li>
											<li>Collaboration on R7D initiatives</li>
										</ul>
										<p>04</p>
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="overview1">
								<div className="wrapper">
									<div className="center">
										<span>Delivery Squad</span>
										<ul>
											<li>Team setup for agile project deliveries</li>
											<li>Full squad with scrum master, BA, developers and QA testers</li>
										</ul>
										<p>05</p>
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="overview1">
								<div className="wrapper">
									<div className="center">
										<span>Support & Operation</span>
										<ul>
											<li>Applications support and maintenance</li>
											<li>Penetration & load test</li>
											<li>Cloud storage & hosting </li>
											<li>Cloud migration</li>
										</ul>
										<p>06</p>
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="overview1">
								<div className="wrapper">
									<div className="center">
										<span>Hardware</span>
										<ul>
											<li>IT Devices and Workstations</li>
											<li>IoT Devices and Sensors</li>
										</ul>
										<p>07</p>
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="overview1">
								<div className="wrapper">
									<div className="center">
										<span>Systems Integration</span>
										<ul>
											<li>Various integration with partners platform (ie. WhatsApp, SMS, IVR, AI, Data Analytics, Chatbot, Zoom)</li>
										</ul>
										<p>08</p>
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="overview1">
								<div className="wrapper">
									<div className="center">
										<span>Product Distributor</span>
										<ul>
											<li>Reseller and solutions provider on ready products from principal</li>
											<li>ERP, smart technologies to city, forklift, hotel, medical, office, warehouse, factory, utilities and supply chain</li>
										</ul>
										<p>09</p>
									</div>
								</div>
							</div>
						</SwiperSlide>
					</>
				)}

				{props === "get" && (
					<>
						<SwiperSlide>
							<div className="get">
								<div className="box box--short">
									<div className="box__info">Enhanced data collection</div>
									<div className="box__icon">
										<img src={whatyouget01Icon} alt="whatyouget01Icon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="get">
								<div className="box box--short">
									<div className="box__info">Greater resource management</div>
									<div className="box__icon">
										<img src={whatyouget02Icon} alt="whatyouget02Icon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="get">
								<div className="box box--short">
									<div className="box__info">Data-driven customer insights</div>
									<div className="box__icon">
										<img src={whatyouget03Icon} alt="whatyouget03Icon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="get">
								<div className="box box--short">
									<div className="box__info">An overall better customer experience</div>
									<div className="box__icon">
										<img src={whatyouget04Icon} alt="whatyouget04Icon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="get">
								<div className="box box--short">
									<div className="box__info">Encourages digital culture (with improved collaboration)</div>
									<div className="box__icon">
										<img src={whatyouget05Icon} alt="whatyouget05Icon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="get">
								<div className="box box--short">
									<div className="box__info">Increased agility</div>
									<div className="box__icon">
										<img src={whatyouget06Icon} alt="whatyouget06Icon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="get">
								<div className="box box--short">
									<div className="box__info">Improved productivity</div>
									<div className="box__icon">
										<img src={whatyouget07Icon} alt="whatyouget07Icon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="get">
								<div className="box box--short">
									<div className="box__info">Enhanced data collection</div>
									<div className="box__icon">
										<img src={whatyouget08Icon} alt="whatyouget08Icon" style={{ width: "64px", height: "64px" }} />
									</div>
								</div>
							</div>
						</SwiperSlide>
					</>
				)}
			</StyledSwiper>
		</>
	);
};
export default AppSwiper;
