import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import pathnames from "routes/pathnames";

import resolutionImg from "assets/images/pages/page-home/thumb-it-resolution.jpg";
import squadImg from "assets/images/pages/page-home/thumb-delivery-squad.jpg";
import superappImg from "assets/images/pages/page-home/thumb-superapp.jpg";
import productImg from "assets/images/pages/page-home/thumb-ready-product.jpg";
import circleArrowIcon from "assets/images/circle-arrow-white.png";
import circleArrowBlueIcon from "assets/images/circle-arrow-blue.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";

// import required modules
import { Scrollbar, Autoplay } from "swiper";

import { styled } from "@mui/system";

const AppSwiperService = ({ props }: any) => {
	const navigate = useNavigate();
	const StyledSwiper = styled(Swiper)(({ theme }: any) => ({
		padding: "0",
	}));

	return (
		<>
			<StyledSwiper
				slidesPerView={1}
				spaceBetween={10}
				autoplay={{
					delay: 2500,
					disableOnInteraction: false,
				}}
				breakpoints={{
					640: {
						slidesPerView: 2,
						spaceBetween: 20,
					},
					768: {
						slidesPerView: 4,
						spaceBetween: 40,
					},
					1024: {
						slidesPerView: 5,
						spaceBetween: 50,
					},
					1152: {
						slidesPerView: 6,
						spaceBetween: 60,
					},
					1280: {
						slidesPerView: 7,
						spaceBetween: 70,
					},
					1408: {
						slidesPerView: 8,
						spaceBetween: 80,
					},
					1536: {
						slidesPerView: 9,
						spaceBetween: 90,
					},
				}}
				scrollbar={{
					hide: false,
				}}
				modules={[Scrollbar, Autoplay]}
				className="mySwiper"
				sx={{
					"& .swiper-scrollbar swiper-scrollbar-horizontal": { background: "#BED9FA" },
					"& .swiper-scrollbar-horizontal": { bottom: "0px", background: "#BED9FA" },
					"& .swiper-scrollbar-drag": { background: "#116AD3" },
				}}
			>
				<SwiperSlide>
					<div className="service-container">
						<div className="pic-wrapper">
							<img src={resolutionImg} alt="resolutionImg" className="pic" />
						</div>
						<div>
							<div className="title">
								<span>IT Resolution & Strategy</span>
							</div>
							<div className="detail">
								<span>Consultation with assessment of fundamentally rethinking to the business models and processes.</span>
							</div>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="service-container">
						<div className="pic-wrapper">
							<img src={squadImg} alt="squadImg" className="pic" />
						</div>
						<div>
							<div className="title">
								<span>Digitalization Delivery Squad</span>
							</div>
							<div className="detail">
								<span>Digital team setup to deliver your MVPs.</span>
							</div>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="service-container">
						<div className="pic-wrapper">
							<img src={superappImg} alt="superappImg" className="pic" />
						</div>
						<div>
							<div className="title">
								<span>Super App Development</span>
							</div>
							<div className="detail">
								<span>A closed ecosystem of many apps that people would use everyday.</span>
							</div>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="service-container">
						<div className="pic-wrapper">
							<img src={productImg} alt="productImg" className="pic" />
						</div>
						<div>
							<div className="title">
								<span>The Ready Products</span>
							</div>
							<div className="detail">
								<span>We provide recommendation and customization to what works best to your use cases.</span>
							</div>
							{props === "service" && (
								<div className="app-button-frames">
									<button
										className="service-container__button"
										onClick={() => {
											navigate(pathnames.PageProduct);
										}}
									>
										<div>FIND OUT MORE</div>
										<div className="arrowW">
											<img src={circleArrowIcon} alt="white arrow" />
										</div>
										<div className="arrowB">
											<img src={circleArrowBlueIcon} alt="blue arrow" />
										</div>
									</button>
								</div>
							)}
						</div>
					</div>
				</SwiperSlide>
			</StyledSwiper>
		</>
	);
};
export default AppSwiperService;
