import * as React from "react";
import { Link } from "react-router-dom";
import pathnames from "routes/pathnames";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import close from "assets/images/close.png";
import menu from "assets/images/menu.png";

type Anchor = "top" | "left" | "bottom" | "right";

const AppDrawer = () => {
	const [state, setState] = React.useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});

	const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
		if (event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};

	const list = (anchor: Anchor) => (
		<Box
			// sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 616, height: "100%" }}
			className="app-drawer"
			role="presentation"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
		>
			<div className="right-menu">
				<div className="right-menu__header">
					<div className="grid-container">
						<Link to={pathnames.PageHome} className="grid-item grid-item__link">
							<span className="hoverSlash hoverSlash--title">/ </span>
							<span className="hoverSlash--title">Home</span>
						</Link>
						<Link to={pathnames.PageWhoWeAreJoin.replace(":link", "join")} className="grid-item grid-item__link">
							<span className="hoverSlash hoverSlash--title">/ </span>
							<span className="hoverSlash--title">Join the Team</span>
						</Link>
						<div className="grid-item grid-item__link">
							<a href="https://hr.altecflex.com/login">
								<span className="hoverSlash hoverSlash--title">/ </span>
								<span className="hoverSlash--title">Login HRMS</span>
							</a>
						</div>
						<div className="grid-item">
							<img src={close} alt="close-icon" />
						</div>
					</div>
				</div>
				<div className="right-menu__context">
					<div className="box">
						<Link to={pathnames.PageWhoWeAre} className="box__link">
							<span className="hoverSlash hoverSlash--title">/ </span>
							<span className="hoverSlash--title">WHO WE ARE</span> <br />
							<div className="hoverSlash--text">Know more about ATF</div>
						</Link>
						<br />
						<Link to={pathnames.PageService} className="box__link">
							<span className="hoverSlash hoverSlash--title">/ </span>
							<span className="hoverSlash--title">SERVICES</span> <br />
							<div className="hoverSlash--text">Take your business to next level</div>
						</Link>
						<br />
						<Link to={pathnames.PageProduct} className="box__link">
							<span className="hoverSlash hoverSlash--title">/ </span>
							<span className="hoverSlash--title">PRODUCTS</span> <br />
							<div className="hoverSlash--text">Check out ATF products</div>
						</Link>
						<br />
						<Link to={pathnames.PageChatWithUs} className="box__link">
							<span className="hoverSlash hoverSlash--title">/ </span>
							<span className="hoverSlash--title">CHAT WITH US</span> <br />
							<div className="hoverSlash--text">We like to hear from you</div>
						</Link>
					</div>
				</div>
			</div>
		</Box>
	);

	return (
		<>
			{(["right"] as const).map((anchor) => (
				<React.Fragment key={anchor}>
					<img src={menu} onClick={toggleDrawer(anchor, true)} style={{ cursor: "pointer", float: "right", height: "36px", width: "36px" }} alt="menu" />
					<Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
						{list(anchor)}
					</Drawer>
				</React.Fragment>
			))}
		</>
	);
};
export default AppDrawer;
