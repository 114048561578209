const API = {
    ENDPOINT_PATH: {
        // document-controller
        GET_DOCUMENT: "/document/",

        // enquiry-controller
        POST_GENERAL: "/enquiry/general",
        POST_JOB: "/enquiry/job",

        // listing controller
        GET_LISTING: "/listing/",
    }
}

export default API;