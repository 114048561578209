import { createTheme } from "@mui/material/styles";

const theme = createTheme({
	palette: {
		error: {
			main: "#d41e1e",
		},
	},
	components: {
		MuiList: {
			styleOverrides: {
				root: {
					paddingTop: 0,
					paddingBottom: 0,
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					backgroundColor: "#ffffff",

					"&.Mui-disabled": {
						opacity: 0.2,
					},

					"&.Mui-selected": {
						color: "#ffffff",
						backgroundColor: "#4d4d4d",

						"&.Mui-focusVisible": {
							color: "#4d4d4d",
							backgroundColor: "#ffffff",
						},

						"&:hover": {
							color: "#ffffff",
							backgroundColor: "#292929",
						},
					},
				},
			},
		},
		MuiButtonBase: {
			styleOverrides: {
				root: {
					backgroundColor: "#ffffff",
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					outline: "none",
				},
				input: {
					outline: "none",
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				notchedOutline: {
					border: "1px solid #eaeaea !important",
				},
			},
		},
	},
});

export default theme;
