//library
import { useCallback, useEffect, useMemo } from "react";
import pathnames from "routes/pathnames";
import { Link, useLocation, useNavigate } from "react-router-dom";

// component
import AppSwiper from "components/pages/page-home/app-swiper";
import AppDrawer from "components/pages/menu/app-drawer";
import AppSwiperService from "components/pages/page-home/app-swiper-service";
import AppFooter from "../components/app-footer";

// assest
import logo from "assets/images/atf-logo.png";
import aceattImg from "assets/images/pages/page-home/strategic-partners/small/aceatt.png";
import alicloudImg from "assets/images/pages/page-home/strategic-partners/partner-alicloud.png";
import awsImg from "assets/images/pages/page-home/strategic-partners/small/aws.png";
import nextEraImg from "assets/images/pages/page-home/strategic-partners/small/next-era.png";
import remicleImg from "assets/images/pages/page-home/strategic-partners/small/remicle.png";
import teamCloudImg from "assets/images/pages/page-home/strategic-partners/small/team-cloud.png";
import vonageImg from "assets/images/pages/page-home/strategic-partners/small/vonage.png";
import nexagateImg from "assets/images/pages/page-home/strategic-partners/partner-nexagate.png";
import zoomImg from "assets/images/pages/page-home/strategic-partners/small/zoom.png";
import huaweiImg from "assets/images/pages/page-home/strategic-partners/small/huawei.png";
import adImg from "assets/images/pages/page-home/strategic-partners/small/mega-advertising.png";
import verintImg from "assets/images/pages/page-home/strategic-partners/small/verint.png";
import agileSquadImg from "assets/images/pages/page-home/home-agile-bg.jpg";
import atfIcon from "assets/images/pages/page-home/atf-icon.png";
import circleArrowIcon from "assets/images/circle-arrow-white.png";
import circleArrowBlueIcon from "assets/images/circle-arrow-blue.png";
import rocketIcon from "assets/images/pages/page-home/rocket.png";
import cupIcon from "assets/images/pages/page-home/cup.png";
import resolutionImg from "assets/images/pages/page-home/thumb-it-resolution.jpg";
import squadImg from "assets/images/pages/page-home/thumb-delivery-squad.jpg";
import superappImg from "assets/images/pages/page-home/thumb-superapp.jpg";
import productImg from "assets/images/pages/page-home/thumb-ready-product.jpg";

const PageHome = () => {
	const navigate = useNavigate();
	const location = useLocation();
	useEffect(() => {
		const w = window.scrollTo(0, 0);
		return () => w;
	}, [location]);

	const onHandleScrollAnimation = useCallback(() => {
		const w = document.getElementById("root");
		if (!w) return;
		const pageTop = w?.scrollTop;
		const ani2 = document.getElementById("animation-2");
		const ani3 = document.getElementById("animation-3");

		if (ani2) {
			if (pageTop > ani2.offsetHeight) {
				ani2?.getElementsByClassName("intro")[0]?.classList?.add("fadeInAndUp");
			}
		}
		if (ani3) {
			if (pageTop + 200 > ani3.offsetHeight) {
				ani3?.getElementsByClassName("text")[0]?.classList?.add("fadeInAndUp");
			}
		}

		const onHandleAddClass = (item: any, i: number) => {
			setTimeout(() => {
				item.classList?.add("fadeInAndUp");
			}, 100 * i);
		};

		const onHandleAddClass1 = (item: any, i: number) => {
			setTimeout(() => {
				item.classList?.add("fadeInAndUp");
			}, 60 * i);
		};

		const pageBottom = pageTop + window.innerHeight;
		const ani4 = document.getElementById("animation-4");
		const ani5 = document.getElementById("animation-5");
		const ani6 = document.getElementById("animation-6");

		if (ani4) {
			if (pageBottom + 300 > ani4.offsetTop + ani4.offsetTop / 3) {
				const list = ani4.querySelectorAll("div");
				for (let i = 0; i < list.length; ++i) {
					let item = list[i];
					onHandleAddClass(item, i);
				}
			}
		}
		if (ani5) {
			if (pageBottom + 350 > ani5.offsetTop + ani5.offsetTop / 3) {
				const list = ani5.querySelectorAll("div");
				for (let i = 0; i < list.length; ++i) {
					let item = list[i];
					onHandleAddClass1(item, i);
				}
			}
		}
		if (ani6) {
			if (pageTop + 500 > ani6.offsetHeight) {
				ani6?.classList?.add("fadeInAndUp");
			}
		}

		const ani7 = document.getElementById("animation-7");
		if (ani7) {
			if (pageTop - 500 > ani7.offsetHeight) {
				ani7?.getElementsByClassName("slideImg")[0]?.classList?.add("fadeInAndLeft");
			}
		}

		const ani8 = document.getElementById("animation-8");
		if (ani8) {
			if (pageTop - 1200 > ani8.offsetHeight) {
				ani8?.getElementsByClassName("slide-up")[0]?.classList?.add("fadeInAndUp");
				ani8?.getElementsByClassName("slide-up")[1]?.classList?.add("fadeInAndUp");
				ani8?.getElementsByClassName("slide-down")[0]?.classList?.add("fadeInAndDown");
				ani8?.getElementsByClassName("slide-down")[1]?.classList?.add("fadeInAndDown");
			}
		}

		const ani9 = document.getElementById("animation-9");
		if (ani9) {
			if (pageTop - 1200 > ani9.offsetHeight) {
				ani9?.getElementsByClassName("slide-up")[0]?.classList?.add("fadeInAndUp");
				ani9?.getElementsByClassName("slide-up")[1]?.classList?.add("fadeInAndUp");
				ani9?.getElementsByClassName("slide-down")[0]?.classList?.add("fadeInAndDown");
				ani9?.getElementsByClassName("slide-down")[1]?.classList?.add("fadeInAndDown");
			}
		}

		const ani10 = document.getElementById("animation-10");
		if (ani10) {
			if (pageTop - 500 > ani10.offsetHeight) {
				ani10?.getElementsByClassName("section-4__description")[0]?.classList?.add("fadeInAndRight");
			}
		}
	}, []);

	useEffect(() => {
		const w = document.getElementById("root");
		if (w) {
			w.addEventListener("scroll", onHandleScrollAnimation, { capture: true });
			return () => w.removeEventListener("scroll", onHandleScrollAnimation);
		}
	}, [onHandleScrollAnimation]);

	useEffect(() => {
		const items = ["Project Manager", "Business Analysts", "UI/UX Designers", "Software Developers", "QA & RPA Expertise", "Data Engineers", "Devops Engineers", "IT OPS Executives", "IT OPS Executives"];
		const container = document.getElementById("banner-items");

		const onHandleSlideUp = (item: HTMLLIElement, i: number) => {
			const lastItem = i === items.length - 1;

			setTimeout(() => {
				if (container && container.lastChild) container?.removeChild(container.lastChild);
				container?.appendChild(item);
				item.classList.add("slide-up-wording");

				if (lastItem) {
					onHandleAnimate();
				}
			}, 3000 * i);
		};

		const onHandleAnimate = () => {
			items.forEach((o, i) => {
				const item = document.createElement("li");
				item.className = "list__item";
				item.innerHTML = o;
				onHandleSlideUp(item, i);
			});
		};

		onHandleAnimate();
	}, []);

	//prettier-ignore
	const serviceData = useMemo(() => [
		{
			image: resolutionImg,
			title: "IT Resolution & Strategy",
			description: "Consultation with assessment of fundamentally rethinking to the business models and processes.",
		},
		{
			image: squadImg,
			title: "Digitalization Delivery Squad",
			description: "Digital team setup to deliver your MVPs.",
		},
		{
			image: superappImg,
			title: "Super App Development",
			description: "A closed ecosystem of many apps that people would use everyday.",
		},
		{
			image: productImg,
			title: "The Ready Products",
			description: "We provide recommendation and customization to what works best to your use cases.",
		},
	], []);

	return (
		<div className="page-home">
			<div className="home">
				<div className="top-menu">
					<div className="img-size">
						<Link to={pathnames.PageHome}>
							<img src={logo} alt="logo" />
						</Link>
						<AppDrawer />
					</div>
				</div>
				<Link to={pathnames.PageChatWithUs} className="chat-container">
					<button className="chat-container__btn">
						<div className="icon"></div>
						<div className="showT">Chat with us</div>
					</button>
				</Link>
				<div className="home-container">
					<div className="home__height">
						<div className="header"></div>
					</div>
					<div className="home-wrapper">
						<div className="context">
							<div className="content"></div>
							<div className="context__text">
								<span className="context__we-are">We are</span>
								<span className="content__container">
									<ul className="list" id="banner-items" />
								</span>
								<span className="context__last-text">to help business with digital transformation</span>
							</div>
						</div>
					</div>
				</div>
				<div className="section-2">
					<div className="section-2__info" id="animation-2">
						<p className={window.screen.width < 767 ? "intro fadeInAndUp" : "intro"}>
							If you are looking for a reliable technology service provider with whom you share your mind and get the best digital roadmap you’ve been looking for, we are here.
							<br /> <br />
							With years of experiences in implementing all scale of innovation platforms, we’ve mastered the skills of studying the market for you and helping you cope up with the latest trends that’ll take your
							business to the next level.
						</p>
					</div>
				</div>
				<div className="section-2">
					<div className="section-2__title" id="animation-3">
						<p className="text">Our Strategic Partners</p>
						<div className="middle">
							<div className="divider"></div>
						</div>
					</div>
				</div>
				<div className="section-2">
					<div className="section-2__grid-container" id="animation-4">
						<div className="drop-shadow">
							<div className="item">
								<div className="img-container">
									<img src={aceattImg} alt="ace att" />
								</div>
							</div>
						</div>
						<div className="drop-shadow">
							<div className="item">
								<div className="img-container">
									<img src={alicloudImg} alt="ali cloud" />
								</div>
							</div>
						</div>
						<div className="drop-shadow">
							<div className="item">
								<div className="img-container">
									<img src={awsImg} alt="aws" />
								</div>
							</div>
						</div>

						<div className="drop-shadow">
							<div className="item">
								<div className="img-container">
									<img src={huaweiImg} alt="huawei" />
								</div>
							</div>
						</div>
						<div className="drop-shadow">
							<div className="item">
								<div className="img-container">
									<img src={adImg} alt="mega advertising" />
								</div>
							</div>
						</div>
						<div className="drop-shadow">
							<div className="item">
								<div className="img-container">
									<img src={nextEraImg} alt="nextEra" />
								</div>
							</div>
						</div>
						<div className="drop-shadow">
							<div className="item">
								<div className="img-container">
									<img src={remicleImg} alt="remicleImg" />
								</div>
							</div>
						</div>
						<div className="drop-shadow">
							<div className="item">
								<div className="img-container">
									<img src={teamCloudImg} alt="teamCloudImg" />
								</div>
							</div>
						</div>
						<div className="drop-shadow">
							<div className="item">
								<div className="img-container">
									<img src={verintImg} alt="verint" />
								</div>
							</div>
						</div>
						<div className="drop-shadow">
							<div className="item">
								<div className="img-container">
									<img src={vonageImg} alt="vonageImg" />
								</div>
							</div>
						</div>
						<div className="drop-shadow">
							<div className="item">
								<div className="img-container">
									<img src={nexagateImg} alt="nexagateImg" />
								</div>
							</div>
						</div>
						<div className="drop-shadow">
							<div className="item">
								<div className="img-container">
									<img src={zoomImg} alt="zoomImg" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="section-2__height">
					<div className="section-2__swiper">
						<div className="side-margin">
							<AppSwiper props={"strategic"}></AppSwiper>
						</div>
					</div>
				</div>
				<div className="section-3">
					<div className="section-3__wrapper">
						<div className="row" id="animation-5">
							{serviceData.map((o, i) => {
								return (
									<div className="section-3__card" key={i}>
										<div className="section-3__content">
											<div className="section-3__card-image">
												<img src={o.image} alt="resolutionImg" />
											</div>
											<p className="section-3__card-title">{o.title}</p>
											<p className="section-3__card-description">{o.description}</p>
										</div>
									</div>
								);
							})}
						</div>
					</div>
					<div className="section-3__slider">
						<div className="swiper-container">
							<AppSwiperService props={"home"} />
						</div>
					</div>

					<div className="section-3__button-container" id="animation-6">
						<div className="app-button-frames">
							<button
								className="section-3__button"
								onClick={() => {
									navigate(pathnames.PageService);
								}}
							>
								<div>VIEW MORE SERVICES</div>
								<div className="arrowW">
									<img src={circleArrowIcon} alt="white arrow" />
								</div>
								<div className="arrowB">
									<img src={circleArrowBlueIcon} alt="blue arrow" />
								</div>
							</button>
						</div>
					</div>
				</div>
				<div>
					<div className="section-4">
						<div className="grid-container-element1 box grid-container-element1--background">
							<div className="grid-child-element1">{/* transparent */}</div>
							<div className="grid-child-element1__isInline" id="animation-7">
								<div className="slideImg">
									<img className="squadImg" src={agileSquadImg} alt="agile squad" />
								</div>
							</div>
						</div>
						<div className="grid-container-element2 box">
							<div className="grid-child-element2--align-right" id="animation-8">
								<p className="slide-up">AGILE</p>
								<p className="slide-up">AGILE</p>
								<p className="title">AGILE</p>
								<p className="slide-down">AGILE</p>
								<p className="slide-down">AGILE</p>
							</div>
							<div className="grid-child-element2--align-center" id="animation-9">
								<p className="slide-up">SQUAD</p>
								<p className="slide-up">SQUAD</p>
								<p className="title">SQUAD</p>
								<p className="slide-down">SQUAD</p>
								<p className="slide-down">SQUAD</p>
							</div>
						</div>
						<div className="grid-container-element1 box">
							<div className="grid-child-element1" id="animation-10">
								<div className="section-4__description">
									<div>
										<div className="mobile-title">
											<div className="mobile-title__text">AGILE SQUAD</div>
											<div className="mobile-title__slash">{"////////////"}</div>
										</div>
										<div className="text-container">
											<p className="showSlash">/</p>
											<span className="agile-text">
												A full-spectrum software delivery partner by bringing end-to-end approach helps organizations provide high-quality software delivery and improve responsiveness to business and
												customer demands.
											</span>
										</div>
										<div className="text-container">
											<button
												className="btn"
												onClick={() => {
													navigate(pathnames.PageChatWithUs);
												}}
											>
												<div className="text-container__text">Talk to us</div>
												<div className="arrowW">
													<img src={circleArrowIcon} alt="arrow" />
												</div>
												<div className="arrowB">
													<img src={circleArrowBlueIcon} alt="arrow" />
												</div>
											</button>
										</div>
									</div>
								</div>
							</div>
							<div className="grid-child-element1__isInline">{/* transparent */}</div>
						</div>
					</div>
				</div>

				<div className="section-5">
					<div className="section-5__flex">
						<div className="row">
							<div className="column">
								<div className="card">
									<div className="connect">
										<span>/</span>
										<p>CONNECT WITH ALTECFLEX</p>
									</div>
									<div className="mobile-connect">
										<div className="connect__title">CONNECT WITH ALTECFLEX</div>
										<div className="connect__slash">{"/////////////////////////////////"}</div>
									</div>
								</div>
							</div>

							<div className="column">
								<Link to={pathnames.PageWhoWeAre} className="card">
									<button className="btn--blue">
										<div className="button-title">
											<div>ABOUT ATF</div>
											<div>
												<img src={circleArrowIcon} alt="circle" />
											</div>
										</div>
										<p className="button-title--subTitle">Get to know more about who we are</p>
										<div className="flex-end">
											<img src={atfIcon} alt="atf icon" />
										</div>
									</button>
								</Link>
							</div>

							<div className="column">
								<Link to={pathnames.PageChatWithUs} className="card">
									<button className="btn--white">
										<div className="button-title">
											<div>CHAT WITH US</div>
											<div>
												<img src={circleArrowBlueIcon} alt="circle" />
											</div>
										</div>
										<p className="button-title--subTitle">Take your business to next level with us!</p>
										<div className="flex-end">
											<img src={rocketIcon} alt="rocket icon" />
										</div>
									</button>
								</Link>
							</div>

							<div className="column">
								<Link to={pathnames.PageWhoWeAreJoin.replace(":link", "join")} className="card">
									<button className="btn--yellow">
										<div className="button-title">
											<div>JOIN US</div>
											<div>
												<img src={circleArrowBlueIcon} alt="circle" />
											</div>
										</div>
										<p className="button-title--subTitle">We are constantly looking for talent to pioneer the tech industry</p>
										<div className="flex-end">
											<img src={cupIcon} alt="cup icon" />
										</div>
									</button>
								</Link>
							</div>
						</div>
					</div>
				</div>

				<AppFooter />
			</div>
		</div>
	);
};

export default PageHome;
