import { useCallback, useEffect, useMemo, useState } from "react";

import AppDrawer from "components/pages/menu/app-drawer";
import { Link } from "react-router-dom";
import pathnames from "routes/pathnames";
import AppFooter from "../components/app-footer";
import AppSwiper from "components/pages/page-home/app-swiper";

import logo from "assets/images/atf-logo.png";
import circleArrowIcon from "assets/images/circle-arrow-white.png";
import circleArrowBlueIcon from "assets/images/circle-arrow-blue.png";
import atoIcon from "assets/images/pages/page-product/products_ecomm/ico-ato.png";
import integrationIcon from "assets/images/pages/page-product/products_ecomm/ico-integration.png";
import technologyIcon from "assets/images/pages/page-product/products_ecomm/ico-technology.png";
import affordabilityIcon from "assets/images/pages/page-product/products_ecomm/ico-affordability.png";
import streamingIcon from "assets/images/pages/page-product/products_ecomm/key-features/ico-Live Video Streaming.png";
import chatIcon from "assets/images/pages/page-product/products_ecomm/key-features/ico-Online Chat.png";
import catalogueIcon from "assets/images/pages/page-product/products_ecomm/key-features/ico-E-Catalogue.png";
import gatewayIcon from "assets/images/pages/page-product/products_ecomm/key-features/ico-Payment Gateway Integration.png";
import videoIcon from "assets/images/pages/page-product/products_ecomm/key-features/ico-Short Videos.png";
import managementIcon from "assets/images/pages/page-product/products_ecomm/key-features/ico-Event Management.png";
import programIcon from "assets/images/pages/page-product/products_ecomm/key-features/ico-Loyalty Program.png";
import integration1Icon from "assets/images/pages/page-product/products_lms/ico-integration.png";
import assignmentIcon from "assets/images/pages/page-product/products_lms/ico-Task assignment.png";
import engineIcon from "assets/images/pages/page-product/products_lms/ico-Scorecard engine.png";
import underWritingIcon from "assets/images/pages/page-product/products_lms/ico-Underwriting.png";
import routingIcon from "assets/images/pages/page-product/products_lms/ico-Application routing.png";
import configurationIcon from "assets/images/pages/page-product/products_lms/ico-Workflow Configuration.png";
import inprogressIcon from "assets/images/pages/page-product/ico-inprogress.png";

const PageProduct = () => {
	const [product, setProduct] = useState({ eCommerce: true, LMS: false, LOS: false, micro: false, insurance: false });
	const [selectEcommerce, setSelectEcommerce] = useState(false);

	const onSelect = (s: string) => {
		if (s === "eCommerce") {
			setProduct({ eCommerce: true, LMS: false, LOS: false, micro: false, insurance: false });
			setSelectEcommerce(true);
		} else if (s === "LMS") {
			setProduct({ eCommerce: false, LMS: true, LOS: false, micro: false, insurance: false });
		} else if (s === "LOS") {
			setProduct({ eCommerce: false, LMS: false, LOS: true, micro: false, insurance: false });
		} else if (s === "micro") {
			setProduct({ eCommerce: false, LMS: false, LOS: false, micro: true, insurance: false });
		} else if (s === "insurance") {
			setProduct({ eCommerce: false, LMS: false, LOS: false, micro: false, insurance: true });
		}
	};

	const onHandleDownloadPdf = () => {
		let pdfPath = "https://backend.altecflex.asia/media/pdf/sample.pdf";
		if (product.eCommerce) {
			pdfPath = "https://backend.altecflex.asia/media/pdf/eCommerce-product.pdf";
		} else if (product.LMS) {
			pdfPath = "https://backend.altecflex.asia/media/pdf/lead-management-service.pdf";
		} else {
			pdfPath = "https://backend.altecflex.asia/media/pdf/sample.pdf";
		}

		fetch(pdfPath, {
			method: "GET",
		})
			.then((resp) => resp.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.style.display = "none";
				a.href = url;
				// a.download = "sample.pdf"; // the filename you want
				a.download = pdfPath.slice(41, 100);
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
			});
	};

	const onHandleScrollAnimation = useCallback(() => {
		const w = document.getElementById("root");
		if (!w) return;
		const pageTop = w?.scrollTop;
		const ani1 = document.getElementById("animation-1");
		const ani2 = document.getElementById("animation-2");
		const ani3 = document.getElementById("animation-3");
		const ani4 = document.getElementById("animation-4");
		const ani5 = document.getElementById("animation-5");
		const pageBottom = pageTop + window.innerHeight;

		const onHandleAddClass = (item: any, i: number) => {
			setTimeout(() => {
				item?.classList?.add("fadeInAndUp");
			}, 60 * i);
		};

		if (ani1) {
			if (pageTop + 500 > ani1.offsetHeight) {
				ani1?.getElementsByClassName("introducing")[0]?.classList?.add("fadeInAndRight");
			}
		}
		if (ani2) {
			if (window.screen.width > 700) {
				if (pageTop + 500 > ani2.offsetHeight) {
					ani2?.getElementsByClassName("text")[0]?.classList?.add("fadeInAndUp");
					ani2?.getElementsByClassName("text--bold")[0]?.classList?.add("fadeInAndUp");
				}
			} else {
				if (pageTop + 1300 > ani2.offsetHeight) {
					ani2?.getElementsByClassName("text")[0]?.classList?.add("fadeInAndUp");
					ani2?.getElementsByClassName("text--bold")[0]?.classList?.add("fadeInAndUp");
				}
			}
		}
		if (ani3) {
			if (pageBottom + 900 > ani3.offsetTop + ani3.offsetTop / 3) {
				const list = ani3.querySelectorAll("div");
				for (let i = 0; i < list.length; ++i) {
					const item = list[i];
					onHandleAddClass(item, i);
				}
			}
		}
		if (ani4) {
			if (pageTop - 2000 > ani4.offsetHeight) {
				ani4?.getElementsByClassName("key-features__title")[0]?.classList?.add("fadeInAndUp");
				ani4?.getElementsByClassName("key-features__center")[0]?.classList?.add("fadeInAndUp");
			}
		}
		if (ani5) {
			if (pageBottom - 2600 > ani5.offsetTop + ani5.offsetTop / 3) {
				const list = ani5.querySelectorAll("div");
				for (let i = 0; i < list.length; ++i) {
					const item = list[i];
					onHandleAddClass(item, i);
				}
			}
		}
	}, []);

	useEffect(() => {
		const w = document.getElementById("root");
		if (w) {
			w.addEventListener("scroll", onHandleScrollAnimation, { capture: true });
			return () => w.removeEventListener("scroll", onHandleScrollAnimation);
		}
	}, [onHandleScrollAnimation]);

	const onHandleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const value = event.currentTarget.value;

		if (value === "eCommerce") {
			setProduct({ eCommerce: true, LMS: false, LOS: false, micro: false, insurance: false });
		} else if (value === "LMS") {
			setProduct({ eCommerce: false, LMS: true, LOS: false, micro: false, insurance: false });
		} else if (value === "LOS") {
			setProduct({ eCommerce: false, LMS: false, LOS: true, micro: false, insurance: false });
		} else if (value === "micro") {
			setProduct({ eCommerce: false, LMS: false, LOS: false, micro: true, insurance: false });
		} else if (value === "insurance") {
			setProduct({ eCommerce: false, LMS: false, LOS: false, micro: false, insurance: true });
		}
	};

	const productData = useMemo(
		() => [
			{
				title: "Live Video Streaming",
				description: "Feature to connect buyer and seller through video streaming. Buyer can still revisit live streaming session that they missed.",
				icon: streamingIcon,
			},
			{
				title: "Online Chat",
				description: "We also offers communication tool to connect buyer & seller in case of any enquiries.",
				icon: chatIcon,
			},
			{
				title: "E-Catalogue",
				description: "Buyer to browse product listed by seller.",
				icon: catalogueIcon,
			},
			{
				title: "Payment Gateway Integration",
				description: "Payment made easier via online payment gateway (FPX, Credit/Debit Card, TnG-Ewallet).",
				icon: gatewayIcon,
			},
			{
				title: "Short Videos / Story",
				description: "Seller's capability to create short sales pitch video content to attract buyers.",
				icon: videoIcon,
			},
			{
				title: "Event Management",
				description: "Keep customer posted on latest announcement / event by providing flexibility for user to configure banner content in real time.",
				icon: managementIcon,
			},
			{
				title: "E-Catalogue",
				description: "Retain customer by tracking customer spending points to be offered as rewards / discounts on their next purchases.",
				icon: programIcon,
			},
		],
		[]
	);

	return (
		<div className="page-product">
			<div className="product">
				<div className="section-1">
					<div className="top-menu">
						<div className="img-size">
							<Link to={pathnames.PageHome}>
								<img src={logo} alt="logo" />
							</Link>
							<AppDrawer />
						</div>
					</div>
					<Link to={pathnames.PageChatWithUs} className="chat-container">
						<button className="chat-container__btn">
							<div className="icon"></div>
							<div className="showT">Chat with us</div>
						</button>
					</Link>
					<div className="section-1__main-img">
						<div className="center">
							<div className="content-container">
								<div className="content__title">
									<span className="content__title--highlight">The Ready Products</span>
								</div>
								<div className="content__description">
									<p>We are distributor and R&D partner of leading technologies products. We provide recommendation and customization to what works best to your use cases.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="section-2">
					<div className="product-menu">
						<div className="content">
							<span className={product.eCommerce ? "content__description active" : "content__description"} onClick={() => onSelect("eCommerce")}>
								<span className="hoverT">/</span> eCommerce
							</span>
							<span className={product.LMS ? "content__description active" : "content__description"} onClick={() => onSelect("LMS")}>
								<span className="hoverT">/</span> LMS
							</span>
							<span className={product.LOS ? "content__description active" : "content__description"} onClick={() => onSelect("LOS")}>
								<span className="hoverT">/</span> LOS
							</span>
							<span className={product.micro ? "content__description active" : "content__description"} onClick={() => onSelect("micro")}>
								<span className="hoverT">/</span> Micro-lending Platform
							</span>
							<span className={product.insurance ? "content__description active" : "content__description"} onClick={() => onSelect("insurance")}>
								<span className="hoverT">/</span> Insurance Platform
							</span>
						</div>
						<div className="dropdown">
							<div className="dropdown__wrapper">
								<span className="slash">/</span>
								<select className="select-frame" onChange={onHandleOnChange}>
									<option value="eCommerce">eCommerce</option>
									<option value="LMS">LMS</option>
									<option value="LOS">LOS</option>
									<option value="micro">Micro-lending Platform</option>
									<option value="insurance">Insurance Platform</option>
								</select>
							</div>
						</div>
					</div>
					<div className="container-1">
						{product.eCommerce && (
							<div className="live-stream-platform">
								<div className={selectEcommerce === false ? "live-stream-platform__left" : "live-stream-platform__left fadeInAndRight"} id={selectEcommerce === false ? "animation-1" : ""}>
									{/* id="animation-1" */}
									<div className="introducing">
										<span className="text--slash">/</span>
										<p className="text">Introducing Live-Commerce Platform</p>
										<div className="center">
											<div className="divider"></div>
										</div>
										<div className="btn-top btn-top--no-flex">
											<button className="btn" onClick={onHandleDownloadPdf}>
												<div>
													<span>DOWNLOAD BROCHURE</span>
												</div>
												<div>
													<div className="arrowW">
														<img src={circleArrowIcon} alt="white arrow" />
													</div>
													<div className="arrowB">
														<img src={circleArrowBlueIcon} alt="blue arrow" />
													</div>
												</div>
											</button>
										</div>
									</div>
								</div>
								<div className={selectEcommerce === false ? "live-stream-platform__right" : "live-stream-platform__right fadeInAndUp"} id={selectEcommerce === false ? "animation-2" : ""}>
									{/* id="animation-2" */}
									<div className="text--bold">Live streaming platform to provide interaction between buyer and seller</div>
									<div className="text">E-Commerce system is a readily made system that allows you to setup a marketplace platform or your own business app with minimal customisation.</div>
									<div className="btn-bottom">
										<button className="btn" onClick={onHandleDownloadPdf}>
											<div>
												<span>DOWNLOAD BROCHURE</span>
											</div>
											<div>
												<div className="arrowW">
													<img src={circleArrowIcon} alt="white arrow" />
												</div>
												<div className="arrowB">
													<img src={circleArrowBlueIcon} alt="blue arrow" />
												</div>
											</div>
										</button>
									</div>
									<div className={selectEcommerce === false ? "wrapper" : "wrapper fadeInAndUp"} id={selectEcommerce === false ? "animation-3" : ""}>
										{/* id="animation-3" */}
										<div className="box">
											<div className="flex-wrap">
												<div className="box__text">
													<p>Assemble to order (ATO)</p>
													<span>Ready component to be customised based on business needs, short implementation time.</span>
												</div>
												<div className="box__icon">
													<img src={atoIcon} alt="atoIcon" />
												</div>
											</div>
										</div>
										<div className="box">
											<div className="flex-wrap">
												<div className="box__text">
													<p>Integration</p>
													<span>Integrate with any systems for information exchange.</span>
												</div>
												<div className="box__icon">
													<img src={integrationIcon} alt="integrationIcon" />
												</div>
											</div>
										</div>
										<div className="box">
											<div className="flex-wrap">
												<div className="box__text">
													<p>Technology</p>
													<span>Keeping up with the latest implementation technology time to time. </span>
												</div>
												<div className="box__icon">
													<img src={technologyIcon} alt="technologyIcon" />
												</div>
											</div>
										</div>
										<div className="box">
											<div className="flex-wrap">
												<div className="box__text">
													<p>Affordability</p>
													<span>Lower price point compared with average market price.</span>
												</div>
												<div className="box__icon">
													<img src={affordabilityIcon} alt="affordabilityIcon" />
												</div>
											</div>
										</div>
									</div>
									<div className="live-swiper">
										<AppSwiper props={"live"} />
									</div>
								</div>
							</div>
						)}
						{product.LMS && (
							<div className="live-stream-platform">
								<div className="live-stream-platform__left fadeInAndRight">
									{/* id="animation-1" */}
									<div className="introducing">
										<span className="text--slash">/</span>
										<p className="text">Your Best Lead Management Start here</p>
										<div className="center">
											<div className="divider"></div>
										</div>
										<div className="btn-top btn-top--no-flex">
											<button className="btn" onClick={onHandleDownloadPdf}>
												<div>
													<span>DOWNLOAD BROCHURE</span>
												</div>
												<div>
													<div className="arrowW">
														<img src={circleArrowIcon} alt="white arrow" />
													</div>
													<div className="arrowB">
														<img src={circleArrowBlueIcon} alt="blue arrow" />
													</div>
												</div>
											</button>
										</div>
									</div>
								</div>
								<div className="live-stream-platform__right fadeInAndUp">
									{/* id="animation-2" */}
									<div className="text">Prioritize and convert revenue-generating leads into happy, engaged customers with improved sales funnel and pipeline visibility at every stage.</div>
									<div className="btn-bottom">
										<button className="btn" onClick={onHandleDownloadPdf}>
											<div>
												<span>DOWNLOAD BROCHURE</span>
											</div>
											<div>
												<div className="arrowW">
													<img src={circleArrowIcon} alt="white arrow" />
												</div>
												<div className="arrowB">
													<img src={circleArrowBlueIcon} alt="blue arrow" />
												</div>
											</div>
										</button>
									</div>
									<div className="wrapper fadeInAndUp">
										{/* id="animation-3" */}
										<div className="box">
											<div className="flex-wrap">
												<div className="box__text">
													<p>Integration</p>
													<span>Open API to integrate with internal system to collect lead records in batch for processing and managing in LMS system.</span>
												</div>
												<div className="box__icon">
													<img src={integration1Icon} alt="integration1Icon" />
												</div>
											</div>
										</div>
										<div className="box">
											<div className="flex-wrap">
												<div className="box__text">
													<p>Task assignment</p>
													<span>Automatically distribute leads to sales user to secure a deal.</span>
												</div>
												<div className="box__icon">
													<img src={assignmentIcon} alt="assignmentIcon" />
												</div>
											</div>
										</div>
										<div className="box">
											<div className="flex-wrap">
												<div className="box__text">
													<p>Scorecard engine</p>
													<span>Manage and configure scorecard to fit marketing evaluation needs, provide automated lead scoring.</span>
												</div>
												<div className="box__icon">
													<img src={engineIcon} alt="engineIcon" />
												</div>
											</div>
										</div>
										<div className="box">
											<div className="flex-wrap">
												<div className="box__text">
													<p>Underwriting</p>
													<span>Capability of user to overwrite scorecard output after detailed study is conducted towards lead records.</span>
												</div>
												<div className="box__icon">
													<img src={underWritingIcon} alt="underWritingIcon" />
												</div>
											</div>
										</div>
										<div className="box">
											<div className="flex-wrap">
												<div className="box__text">
													<p>Application routing</p>
													<span>Sales user to reroute lead back to marketing for further nurturing / evaluation for unsuccessful conversion lead records.</span>
												</div>
												<div className="box__icon">
													<img src={routingIcon} alt="routingIcon" />
												</div>
											</div>
										</div>
										<div className="box">
											<div className="flex-wrap">
												<div className="box__text">
													<p>Workflow Configuration</p>
													<span>Flexibility to configure workflow to keep up with market trends. Provided with the capability to spot and improvise workflow on the fly.</span>
												</div>
												<div className="box__icon">
													<img src={configurationIcon} alt="configurationIcon" />
												</div>
											</div>
										</div>
									</div>
									<div className="live-swiper">
										<AppSwiper props={"lead"} />
									</div>
								</div>
							</div>
						)}
						{(product.LOS || product.micro || product.insurance) && (
							<div className="inprogress">
								<div className="center">
									<img src={inprogressIcon} alt="inprogressIcon" />
								</div>
								<div className="center">
									<p>Preparation in progress, Stay tuned!</p>
								</div>
							</div>
						)}
					</div>
					{product.eCommerce && (
						<div className="section-2__platform-bg">
							<div className="container-2"></div>
						</div>
					)}
					{product.LMS && (
						<div className="section-2__platform-bg--lms">
							<div className="container-2"></div>
						</div>
					)}
				</div>
				{product.eCommerce && (
					<div className="section-3">
						<div className="section-3__container" id="animation-4">
							<div className="key-features__title">
								<span>Key Features</span>
							</div>
							<div className="key-features__center">
								<div className="key-features__divider"></div>
							</div>
						</div>
						<div className="section-3__container section-3__container--content-margin">
							<div className="wrapper" id="animation-5">
								{productData.map((o, k) => {
									return (
										<div className="box" key={k}>
											<div className="box__container">
												<div className="box__wrapper">
													<div className="box__content">
														<div className="box__title">{o.title}</div>
														<div className="box__info">{o.description}</div>
													</div>
													<div className="box__icon">
														<img src={o.icon} alt="icon" />
													</div>
												</div>
											</div>
										</div>
									);
								})}
							</div>
							<div className="features-box">
								<AppSwiper props={"features"} />
							</div>
						</div>
					</div>
				)}

				<AppFooter />
			</div>
		</div>
	);
};

export default PageProduct;
