import { useState } from "react";

import ellipse from "assets/images/pages/page-who-we-are/map/ellipse.png";
import atf from "assets/images/pages/page-who-we-are/map/atf.png";
import sg from "assets/images/pages/page-who-we-are/map/flag-singapore.png";
import th from "assets/images/pages/page-who-we-are/map/flag-thailand.png";
import cm from "assets/images/pages/page-who-we-are/map/flag-cambodia.png";
import vn from "assets/images/pages/page-who-we-are/map/flag-vietnam.png";
import kr from "assets/images/pages/page-who-we-are/map/flag-korea.png";

const AppMap = () => {
	let header = document.getElementById("myDIV");
	let btns = header?.getElementsByClassName("stroke");
	if (btns) {
		for (let i = 0; i < btns.length; i++) {
			btns[i].addEventListener("click", function () {
				let current = document.getElementsByClassName("active");
				current[0].className = current[0].className.replace(" active", " ");
				// this.className += " active";
			});
		}
	}

	const defaultCountries = {
		malaysia: true,
		singapore: false,
		thailand: false,
		cambodia: false,
		vietnam: false,
		korea: false,
	};

	const [countries, setCountries] = useState(defaultCountries);

	const onSelect = (c: string) => {
		if (c === "MY") {
			setCountries({
				malaysia: true,
				singapore: false,
				thailand: false,
				cambodia: false,
				vietnam: false,
				korea: false,
			});
		} else if (c === "SG") {
			setCountries({
				malaysia: false,
				singapore: true,
				thailand: false,
				cambodia: false,
				vietnam: false,
				korea: false,
			});
		} else if (c === "TH") {
			setCountries({
				malaysia: false,
				singapore: false,
				thailand: true,
				cambodia: false,
				vietnam: false,
				korea: false,
			});
		} else if (c === "CM") {
			setCountries({
				malaysia: false,
				singapore: false,
				thailand: false,
				cambodia: true,
				vietnam: false,
				korea: false,
			});
		} else if (c === "VN") {
			setCountries({
				malaysia: false,
				singapore: false,
				thailand: false,
				cambodia: false,
				vietnam: true,
				korea: false,
			});
		} else if (c === "KR") {
			setCountries({
				malaysia: false,
				singapore: false,
				thailand: false,
				cambodia: false,
				vietnam: false,
				korea: true,
			});
		} else {
			setCountries({
				malaysia: false,
				singapore: false,
				thailand: false,
				cambodia: false,
				vietnam: false,
				korea: false,
			});
		}
	};

	return (
		<div className="app-map">
			<div className="map"></div>
			<div className="country">
				<div className="country__container" id="myDIV">
					<div className={countries.malaysia ? "stroke active" : "stroke"} style={{ position: "absolute", bottom: "80px", left: "80px" }} onClick={() => onSelect("MY")}>
						<div>
							<img src={ellipse} alt="ellipse" className="stroke__ellipse" />
							<img src={atf} alt="atf logo" className="stroke__ellipse__icon" />
						</div>
					</div>
					<div className={countries.singapore ? "stroke active" : "stroke"} style={{ position: "absolute", bottom: "15px", left: "150px" }} onClick={() => onSelect("SG")}>
						<div>
							<img src={sg} alt="singapore" className="stroke--icon" />
						</div>
					</div>
					<div className={countries.thailand ? "stroke active" : "stroke"} style={{ position: "absolute", top: "80px", left: "100px" }} onClick={() => onSelect("TH")}>
						<div>
							<img src={th} alt="thailand" className="stroke--icon" />
						</div>
					</div>
					<div className={countries.cambodia ? "stroke active" : "stroke"} style={{ position: "absolute", top: "120px", left: "250px" }} onClick={() => onSelect("CM")}>
						<div>
							<img src={cm} alt="cambodia" className="stroke--icon" />
						</div>
					</div>
					<div className={countries.vietnam ? "stroke active" : "stroke"} style={{ position: "absolute", top: "-10px", left: "310px" }} onClick={() => onSelect("VN")}>
						<div>
							<img src={vn} alt="vietnam" className="stroke--icon" />
						</div>
					</div>
					<div className={countries.korea ? "stroke active" : "stroke"} style={{ position: "absolute", top: "-38px", right: "182px" }} onClick={() => onSelect("KR")}>
						<div>
							<img src={kr} alt="korea" className="stroke--icon" />
						</div>
					</div>
					<div className="intro-map">
						{countries.malaysia && (
							<div className="details details">
								<span className="title">Malaysia (HQ)</span>
								<div className="divider"></div>
								<p className="info">Established in 2018. Headquarter of atf as IT Service Provider hub based at Malaysia to support International clients’ global IT projects and operations</p>
							</div>
						)}
						{countries.singapore && (
							<div className="details details">
								<span className="title">Singapore</span>
								<div className="divider"></div>
								<p className="info">Sales office expansion and setup in SG, the plan is postponed to year 2022 subject to COVID19 situation. Currently has several SG clients</p>
							</div>
						)}
						{countries.thailand && (
							<div className="details details">
								<span className="title">Thailand</span>
								<div className="divider"></div>
								<p className="info">In 2024, sales office expansion with local operational support to FINTECH and IR4.0 clients in the country</p>
							</div>
						)}
						{countries.cambodia && (
							<div className="details details">
								<span className="title">Cambodia</span>
								<div className="divider"></div>
								<p className="info">In 2024, subject to the ongoing progress with existing client, will expand the office to establish smart technologies with partners</p>
							</div>
						)}
						{countries.vietnam && (
							<div className="details details">
								<span className="title">Vietnam</span>
								<div className="divider"></div>
								<p className="info">In 2023, office setup as to address clients’ requirements in taking over various systems handover and support continuity from Vietnam vendors</p>
							</div>
						)}
						{countries.korea && (
							<div className="details details">
								<span className="title">Korea</span>
								<div className="divider"></div>
								<p className="info">
									In 2023, IT operation and cloud service provider setup, as collaborative partnership with Korea client after COVID19 situation, being their global IT and digital transformation vendor for their
									business growth
								</p>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppMap;
