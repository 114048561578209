import { ReactElement, useEffect } from "react";
import { useLocation } from "react-router-dom";

type Props = {
	children: ReactElement;
};

const ScrollToTop = ({ children }: Props) => {
	const location = useLocation();

	useEffect(() => {
		const root = document.getElementById("root");
		if (root) root.scrollTo(0, 0);
	}, [location.pathname]);

	return children;
};

export default ScrollToTop;
