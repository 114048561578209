const pathnames = {
	PageHome: "/",
	PageWhoWeAre: "/who-we-are",
	PageWhoWeAreJoin: "/who-we-are/:link",
	PageService: "/service",
	PageChatWithUs: "/chat-with-us",
	PageProduct: "/product",
};

export default pathnames;
