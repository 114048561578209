import atfLogoIcon from "assets/images/atf-logo-footer.png";

const AppFooter = () => {
	return (
		<div className="app-footer">
			<div className="address-container">
				<div className="address-container__address--logo">
					<img src={atfLogoIcon} alt="atf logo" />
				</div>
				<div className="address-container__address__divider"></div>
				<div className="address-container__address">
					<div className="address-container__address__bold-text">
						<span>Malaysia (HQ)</span>
					</div>
					<div className="address-container__address__normal-text">
						<div>
							<span>Tower 2, Puchong Financial Corporate Centre, Unit 8F-1C, Jalan Puteri 1/2, Bandar Puteri Puchong, 47100 Puchong, Selangor</span>
						</div>
					</div>
				</div>
				<div className="address-container__address">
					<div className="address-container__address__bold-text">
						<span>Thailand</span>
					</div>
					<div className="address-container__address__normal-text--mb">
						<span>Room No. 1814, 18th Floor, Sathorn Thani Tower II, 92/53 North Sathon Road, Silom, Bang Rak, Bangkok 10500</span>
					</div>
				</div>
				<div className="address-container__address">
					<div className="address-container__address__bold-text">
						<span>Singapore</span>
					</div>
					<div className="address-container__address__normal-text--mb">
						<span>Level 49 & 50 One Raffles Quay, Singapore 048583</span>
					</div>
				</div>
				<div className="address-container__address">
					<div className="email2">
						<span style={{ fontWeight: "700" }}>Email: </span>
						<span>admin@altecflex.com</span>
					</div>
				</div>
				<div className="address-container__address">
					<span>Copyright © {new Date().getFullYear()} atf | Altecflex Sdn Bhd. All Rights Reserved.</span>
				</div>
			</div>
		</div>
	);
};

export default AppFooter;
